import API from "@/api";
import formatObjectToQuery from "@/package/helpers/format-object-to-query.helpers";

const quality = window.devicePixelRatio > 1 ? 50 : 30;

const state = {
  sliders: [],
};

const getters = {};

const actions = {
  loadSlider(_, nameSlider) {
    return new Promise((resolve, reject) => {
      API.get(`api/banners/${nameSlider}`)
        .then((response) => {
          const slides = response.data.data.slides.map((item) => {
            const settingsDesktop = formatObjectToQuery({
              width: 1300,
              height: 498,
              quality,
            });

            const settingsMobile = formatObjectToQuery({
              width: 1300,
              height: 856,
              quality,
            });

            const thumbnail_mobile_url =
              item.thumbnail_mobile_url.split("?")[0] + `?${settingsMobile}`;
            const thumbnail_url =
              item.thumbnail_url.split("?")[0] + `?${settingsDesktop}`;

            return { ...item, thumbnail_url, thumbnail_mobile_url };
          });

          resolve({ ...response.data.data, slides });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getSlider({ state, commit, dispatch }, nameSlider) {
    return new Promise((resolve, reject) => {
      const foundSlider = state.sliders.find(
        (item) => item?.slug === nameSlider
      );

      if (foundSlider) {
        resolve(foundSlider);
        return;
      }

      dispatch("loadSlider", nameSlider)
        .then((data) => {
          commit("PUSH_SLIDER", data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  PUSH_SLIDER(state, payload) {
    state.sliders.push(payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
