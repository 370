import API from "@/api";
import store from "./index";

const state = {
  tariff: null,
  tariffs: [],

  previewTariffPrice: null,
};

const getters = {};

const actions = {
  loadTariff({ commit }, tariffId) {
    return new Promise((resolve, reject) => {
      API.get(`api/tariffs/${tariffId}`)
        .then((response) => {
          commit("SET_TARIFF", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadTariffs({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      API.get("api/tariffs")
        .then((response) => {
          // console.log(response.data);
          // todo: УДОЛИТЬ ЭТО
          // console.log(response, rootState.auth.user.tariff.code);
          let newData;

          const func = (price) => {
            newData = response.data.map((item) => {
              if (item.code === "business_d")
                return { ...item, price: price[0] ?? item.price };

              if (item.code === "premium_d")
                return { ...item, price: price[1] ?? item.price };

              if (item.code === "vip_d")
                return { ...item, price: price[2] ?? item.price };

              if (item.code === "vip_gold_d")
                return { ...item, price: price[3] ?? item.price };
            });
          };

          const tariffUser = rootState.auth.user.tariff?.code;

          if (!tariffUser) func([25000, 120000, 300000, null]);
          else if (tariffUser === "smart_c")
            func([23500, 118500, 298500, null]);
          else if (tariffUser === "standard_c")
            func([20000, 115000, 295000, null]);
          else if (tariffUser === "business_d")
            func([null, 95000, 275000, null]);
          else if (tariffUser === "premium_d") func([null, null, 180000, null]);
          else if (tariffUser === "vip_d") func([null, null, null, 250000]);
          else func([null, null, null, null]);

          // const newData = response.data.map((item) => {
          //   if (item.code === "business_d") return { ...item, price: 25000 };
          //
          //   if (item.code === "premium_d") return { ...item, price: 120000 };
          //
          //   if (item.code === "vip_d") return { ...item, price: 300000 };
          //
          //   if (item.code === "vip_gold_d") return { ...item, price: 550000 };
          // });

          commit("SET_TARIFFS", newData);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  buyTariff(_, { tariffId, certificates }) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${tariffId}/buy`, {
        certificates: certificates.length ? certificates : null,
      })
        .then((response) => {
          resolve(response);

          localStorage.removeItem("certificates");
          store.dispatch("createNotification", {
            title: "Поздравляем с покупкой тарифа!",
            type: "success",
          });
        })
        .catch((error) => {
          reject(error);

          store.dispatch("createNotification", {
            title: "При покупке тарифа возникла ошибка!",
            description:
              "Попробуйте снова или обратитесь в техническую поддержку hello@rc.company",
            type: "error",
          });
        });
    });
  },

  buyTariffInstallment(_, tariffId) {
    return new Promise((resolve, reject) => {
      API.post(`/api/refill/${tariffId}/installment`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadPreviewTariffPrice({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/tariffs/${data.tariffId}/preview`, {
        certificates: data.certificates,
      })
        .then((response) => {
          commit("SET_PREVIEW_TARIFF_PRICE", response.data);

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  disableTariffInstallment(_, tariffId) {
    return new Promise((resolve, reject) => {
      API.delete(`/api/tariffs/${tariffId}/installment`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_TARIFF(state, payload) {
    state.tariff = payload;
  },

  SET_TARIFFS(state, payload) {
    state.tariffs = payload;
  },

  SET_PREVIEW_TARIFF_PRICE(state, payload) {
    state.previewTariffPrice = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
